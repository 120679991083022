<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select

              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label />
          </b-col>

          <!-- Search -->

        </b-row>

      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"

        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="VideoList"

        show-empty
        empty-text="  يتم جلب البيانات"
      >
        <template #cell(link)="data">

          <div class="border rounded p-2">
            <!-- <h4 class="mb-1">Video</h4> -->

            <div>
              <iframe
                class="embed-responsive-item"
                type="iframe"
                :src="`https://www.youtube.com/embed/${data.item.link}`"
                allowfullscreen
              />
            </div>
          </div>
        </template>

        <!-- Column: Status -->

        <!-- Column: Actions -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <a
            v-if="$can('delete', 'video')"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </a>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              عرض {{ dataMeta.from }} الى {{ dataMeta.to }} من {{ dataMeta.of }} المدخلات
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalVideos"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {

    // useCousresList,
  
    BCard,
    BRow,
    BCol,

    BTable,
   
    BPagination,

    vSelect,
  },
  setup() {
    const VideoList = ref([])
    const perPageOptions = [25, 50, 100, 200]
    const perPage = ref(10)
    const totalVideos = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const refetchData = () => {
      store.dispatch('gallery/AllVideo', {

        per_page: perPage.value,
        page: currentPage.value,
      })
        .then(response => {
          console.log('AllVideo', response.data.data.total)
          VideoList.value = response.data.data.data
          totalVideos.value = response.data.data.total
        })
    }
    const tableColumns = [

      { label: ' الفيديو', key: 'link' },
      { key: 'actions', label: 'الخيارات' },

    ]
    watch([currentPage, perPage], () => {
      refetchData()
    })
    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalVideos.value,
      }
    })
    const meta = reactive({})
 
    refetchData()
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',

        },
      }).then(result => {
        if (result.value) {
          store.dispatch('gallery/DeletVideo', id)
            .then(response => {
              refetchData()
              Vue.swal({
                title: 'تم الحذف  ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    return {
      tableColumns,
      VideoList,
      DeleteItem,
      meta,
      refetchData,
      perPageOptions,
      totalVideos,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
  .mt-50.col-12 {
    margin: 10px 0px;
}
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }

  </style>
